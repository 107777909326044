import React, { useEffect, useState } from "react";

// Assets
import Logo from "../../assets/images/FishLog Trace.svg";
import CrabMeat from "../../assets/images/crab meat.png";

// Utils
import DeliveryInformation from "./deliveryInformation";
import SupplierInformation from "./SupplierInformation";
import Sustainable from "./sustainable";
import ProductInformation from "./productInformation";
import SeafoodJourney from "./SeafoodJourney";
import ButtonNextPrev from "../../components/Pagination/Button";

const CardProduct = ({ data }) => {
  const dataTrace = data?.traceability_products;
  const [pagin, setPagin] = useState({
    start: 0,
    end: 1,
  });

  const [suppInfo, setSuppInfo] = useState();

  const paginate = () => {
    setSuppInfo(dataTrace.slice(pagin.start, pagin.end));
  };

  useEffect(() => {
    paginate();
  }, [pagin.start, pagin.end]);

  return (
    <div className="card-product">
      <div className="card-product-container">
        <div className="card-product-header">
          <div className="d-flex align-items-center w-100 justify-content-center">
            <img height="40px" src={Logo} alt="logo fishlog" />
          </div>
        </div>

        {dataTrace && dataTrace.length !== 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ButtonNextPrev
              type="mobile"
              data={dataTrace}
              pagin={pagin}
              paginStart={pagin.start}
              paginEnd={pagin.end}
              setPagin={setPagin}
              setArr={setSuppInfo}
            />
          </div>
        )}

        <div className="card-product-body">
          {suppInfo && (
            <>
              <ProductInformation
                data={data}
                CrabMeat={CrabMeat}
                dataTrace={suppInfo[0]}
              />

              <SupplierInformation data={data} dataTrace={suppInfo[0]} />
            </>
          )}

          {suppInfo && data.toggle_sustainability_program && (
            <Sustainable data={data} />
          )}

          <SeafoodJourney data={data} />

          <DeliveryInformation data={data} />
        </div>
      </div>
    </div>
  );
};

export default CardProduct;
