import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Product from "./pages/Product";
// import Batch from '../src/pages/Batch';
// import Contract from '../src/pages/Contract';
// import ContractOverview from '../src/pages/Contract/overview';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Product />} />
        <Route path="/:code" element={<Product />} />
        {/* <Route path="/batch/:id" element={<Batch />} />
        <Route path="/contract" element={<Contract />} />
        <Route path="/contract-overview" element={<ContractOverview />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
