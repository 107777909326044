import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";

import Storage from '../../../assets/images/icons/storage.svg';

// Components
import MapComponent from "../../../components/Map";
import PinIcon from "../../../components/PinMap";
import LineMap from "../../../components/LineMap";

// Utils
import useWindowSize from "../../../components/WinSize";
import SeafoodJourneyMobile from "./SeafoodJourneyMobile";
import SeafoodJourneyDesktop from "./SeafoodJourneyDesktop";

const SeafoodJourney = ({ data }) => {
  const [activeCard, setActiveCard] = useState(null);
  const [winWidth, _] = useWindowSize();
  const [dataCard, setDataCard] = useState({
    image: Storage,
    title: "Storage",
    subtitle: data?.storage,
    lat: data?.storage_latlong[0],
    lng: data?.storage_latlong[1],
    date: data?.storage_date
  });

  // Maps properties
  const [centerPoint, setCenterPoint] = useState({
    lat: -2.538384,
    lng: 113.468978,
  });
  const [zoom, setZoom] = useState(5);

  // Lat long marker and line
  const [latlngStorage, setLatlngStorage] = useState({
    lat: null,
    lng: null,
  });
  const [latlngManufacture, setLatlngManufacture] = useState({
    lat: null,
    lng: null,
  });
  const [latlngDeparture, setLatlngDeparture] = useState({
    lat: null,
    lng: null,
  });
  const [latlngArrival, setLatlngArrival] = useState({
    lat: null,
    lng: null,
  });

  // Set lat long marker and line
  useEffect(() => {
    const storage = data?.storage_latlong
      ? data?.storage_latlong
      : data?.latlong_storage_manufacture?.split(",");
    setLatlngStorage({
      lat: storage[0],
      lng: storage[1],
    });

    const manufacture = data?.manufacture_latlong
      ? data?.manufacture_latlong
      : data?.latlong_storage_manufacture?.split(",");
    setLatlngManufacture({
      lat: manufacture[0],
      lng: manufacture[1],
    });

    const port_of_departure = data?.port_of_departure_latlong
      ? data?.port_of_departure_latlong
      : data?.port_of_departure_latlong?.split(",");
    setLatlngDeparture({
      lat: port_of_departure[0],
      lng: port_of_departure[1],
    });

    const port_of_arrival = data?.destination_latlong
      ? data?.destination_latlong
      : data?.port_of_destination_latlong?.split(",");
    setLatlngArrival({
      lat: port_of_arrival[0],
      lng: port_of_arrival[1],
    });
  }, []);

  const handleChangeActiveCard = (value, latlng) => {
    if (value === activeCard) {
      setActiveCard(null);
      setCenterPoint(latlngArrival);
      setZoom(5);
    } else {
      setActiveCard(value);
      setCenterPoint({ lat: Number(latlng?.lat), lng: Number(latlng?.lng) });
      setZoom(12);
    }
  };

  return (
    <>
      {winWidth >= 560 && (
        <p className="title-head mb-3 mt-3">Your Seafood Journey</p>
      )}
      <Card className="mb-3 w-100 card-border">
        <Card.Body className="p-3">
          {winWidth <= 560 ? (
            <>
              <p className="title-head-mobile mb-3 mt-3">
                Your Seafood Journey
              </p>
              <p className="subtitle mb-3">Location</p>
            </>
          ) : (
            <p className="title-head mb-3">Location</p>
          )}
          <div className="seafood-journey">
            <div className="map me-3 mb-3 mb-lg-0">
              <MapComponent
                center={{
                  latitude: Number(centerPoint.lat),
                  longitude: Number(centerPoint.lng),
                }}
                zoomMaps={zoom}
                height={"300px"}
              >
                <PinIcon
                  type="storage"
                  position={{
                    lat: Number(latlngStorage.lat),
                    lng: Number(latlngStorage.lng),
                  }}
                />

                <LineMap
                  line={[
                    {
                      lat: Number(latlngStorage.lat),
                      lng: Number(latlngStorage.lng),
                    },
                    {
                      lat: Number(latlngManufacture.lat),
                      lng: Number(latlngManufacture.lng),
                    },
                  ]}
                />

                <PinIcon
                  type="manufacture"
                  position={{
                    lat: Number(latlngManufacture.lat),
                    lng: Number(latlngManufacture.lng),
                  }}
                />

                <LineMap
                  line={[
                    {
                      lat: Number(latlngManufacture.lat),
                      lng: Number(latlngManufacture.lng),
                    },
                    {
                      lat: Number(latlngDeparture.lat),
                      lng: Number(latlngDeparture.lng),
                    },
                  ]}
                />

                <PinIcon
                  type="departure"
                  position={{
                    lat: Number(latlngDeparture.lat),
                    lng: Number(latlngDeparture.lng),
                  }}
                />

                <LineMap
                  line={[
                    {
                      lat: Number(latlngDeparture.lat),
                      lng: Number(latlngDeparture.lng),
                    },
                    {
                      lat: Number(latlngArrival.lat),
                      lng: Number(latlngArrival.lng),
                    },
                  ]}
                />

                <PinIcon
                  type="destination"
                  position={{
                    lat: Number(latlngArrival.lat),
                    lng: Number(latlngArrival.lng),
                  }}
                />
              </MapComponent>
            </div>
            
            {winWidth <= 560 ? (
              <>
                <SeafoodJourneyMobile
                  data={data}
                  handleChangeActiveCard={handleChangeActiveCard}
                  dataCard={dataCard}
                  setDataCard={setDataCard}
                  activeCard={activeCard}
                  latlngStorage={latlngStorage}
                  latlngDeparture={latlngDeparture}
                  latlngManufacture={latlngManufacture}
                  latlngArrival={latlngArrival}
                />
              </>
            ) : (
              <>
                <SeafoodJourneyDesktop
                  data={data}
                  handleChangeActiveCard={handleChangeActiveCard}
                  activeCard={activeCard}
                  latlngStorage={latlngStorage}
                  latlngDeparture={latlngDeparture}
                  latlngManufacture={latlngManufacture}
                  latlngArrival={latlngArrival}
                />
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default SeafoodJourney;
