import React, { useState, useEffect } from "react";
import { useLoadScript, GoogleMap } from "@react-google-maps/api";

function MapComponent({
  children,
  center,
  zoomMaps,
  onZoomChanged = () => { },
  onCenterChanged = () => { },
  height,
}) {
  const [mapLoad, setMapLoad] = useState();
  const [centerState, setCenterState] = useState({
    lat: -6.9394297,
    lng: 107.5860329,
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLEMAPS,
  });

  useEffect(() => {
    if (center?.latitude !== undefined) {
      setCenterState({ lat: center?.latitude, lng: center?.longitude });
    }
  }, [center]);

  const renderMap = () => {
    return (
      <GoogleMap
        center={centerState}
        onLoad={(map) => {
          setMapLoad(map);
        }}
        zoom={zoomMaps}
        onCenterChanged={() => {
          onCenterChanged(mapLoad?.center?.lat(), mapLoad?.center?.lng());
        }}
        onZoomChanged={() => {
          onZoomChanged(mapLoad?.zoom);
        }}
        options={{
          restriction: {
            latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
            strictBounds: true,
          },
          mapTypeControl: false,
          mapTypeControlOptions: {
            position: 11.0,
          },
          zoomControlOptions: {
            position: 4.0,
          },
          streetViewControlOptions: {
            position: 4.0,
          },
          fullscreenControlOptions: {
            position: 4.0,
          },
        }}
        mapContainerStyle={{
          height: height,
          width: "100%",
        }}
        resetBoundsOnResize={true}
      >
        {children}
      </GoogleMap>
    );
  };

  return isLoaded ? renderMap() : null;
}

export default React.memo(MapComponent);
