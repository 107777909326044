import React, { useState, useEffect, useRef } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

// Components
import Header from "../../components/Header";
import Skeleton from "../../components/Skeleton";

// Assets
import LogoFishlog from "../../assets/images/FishLog Trace Logo white.svg";
import FishlogTraceWhite from "../../assets/images/FishLog Trace white.png";
import FishlogTraceBlue from "../../assets/images/FishLog Trace blue.png";
import PhoneFLI from "../../assets/images/phone-FLI.png";
import Phonetrace from "../../assets/images/phone trace.png";

import ProductInfo from "../../assets/images/icons/product info.svg";
import ProductJourney from "../../assets/images/icons/product journey.svg";
import Delivery from "../../assets/images/icons/delivery.svg";
import DedicationToQuality from "../../assets/images/icons/DEDICATION TO QUALITY 1.svg";
import EthicalFishing from "../../assets/images/icons/ETHICAL FISHING 1.svg";
import VerifiedOrigins from "../../assets/images/icons/VERIFIED ORIGINS 1.svg";

// Services
import {
  getDataTraceabilityByProductionCode,
  getDataTraceabilityByTraceabilityCode,
} from "../../services/traceability";
import CardProductTraceability from "./cardProduct";
import CardProductProduction from "./carProduct-old";

const Scanner = () => {
  const { code } = useParams();
  const { register, watch, setValue } = useForm();
  const resultsProduct = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const getData = (code) => {
    getDataTraceabilityByTraceabilityCode(code)
      .then((response) => {
        setIsLoading(false);
        if (response.data.data) {
          setData(response.data.data);
        }
      })
      .catch((error) => {
        getDataTraceabilityByProductionCode(code)
          .then((response) => {
            setIsLoading(false);
            if (response.data.data) {
              setData(response.data.data);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            setData(1);
          });
      });
  };

  const onScan = () => {
    const code = watch("production_code");
    if (code !== "") {
      setIsLoading(true);
      getData(code);
    } else {
      setData(null);
    }

    resultsProduct.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  useEffect(() => {
    if (code !== undefined) {
      setValue("production_code", code);
      onScan();
    }
  }, [code]);

  const handleKeydown = (code) => {
    if (code.key === "Enter") {
      onScan();
    }
  };

  return (
    <>
      {/* Header */}
      <Header />

      {/* Section 1 Start */}
      <div className="section-1">
        <div className="logo">
          <img src={LogoFishlog} alt="Logo Fishlog" />
        </div>

        <div className="card-container">
          <Card>
            <Card.Body>
              <div className="header-section">
                <div>
                  <h3 className="title">Tracking</h3>
                </div>
              </div>

              <Form.Group className="mb-3 d-flex">
                <Form.Control
                  type="text"
                  disabled={code !== undefined}
                  {...register("production_code")}
                  // defaultValue="TE001"
                  // defaultValue="3112810120A1F"
                  onKeyDown={handleKeydown}
                />

                <Button
                  variant="primary"
                  className="ms-3 px-5"
                  onClick={onScan}
                >
                  Check
                </Button>
              </Form.Group>

              {/* <table className="table-production-code">
                <thead className="table-header-production-code">
                  <tr>
                    <th className="item-title">TUNA</th>
                    <th className="item-title">SHRIMP</th>
                    <th className="item-title">CRAB MEAT - CLAW MEAT</th>
                    <th className="item-title">CRAB MEAT - SPECIAL</th>
                  </tr>
                </thead>
                <tbody className="table-body-production-code">
                  <tr>
                    <td rowSpan={2} className="item-data">
                      8329011
                    </td>
                    <td rowSpan={2} className="item-data">
                      C155231A00
                    </td>
                    <td className="item-data">3102670118A1A | 3102670218A1A</td>
                    <td className="item-data">3102660218A1A | 3102660118A1A</td>
                  </tr>
                  <tr>
                    <td className="item-data">3102670318A1A | 3102870102A1A</td>
                    <td className="item-data">3102860102A1A</td>
                  </tr>
                </tbody>
              </table>

              <Card className="card-production-code">
                <Card.Header className="card-header-production-code">
                  TUNA
                </Card.Header>
                <Card.Body className="card-body-production-code">
                  <p className="text mb-1">8329011</p>
                </Card.Body>
              </Card>
              <Card className="card-production-code">
                <Card.Header className="card-header-production-code">
                  SHRIMP
                </Card.Header>
                <Card.Body className="card-body-production-code">
                  <p className="text mb-1">C155231A00 </p>
                </Card.Body>
              </Card>
              <Card className="card-production-code">
                <Card.Header className="card-header-production-code">
                  CRAB MEAT - CLAW MEAT
                </Card.Header>
                <Card.Body className="card-body-production-code">
                  <p className="text mb-1">3102670118A1A</p>
                  <p className="text mb-1">3102670218A1A</p>
                  <p className="text mb-1">3102670318A1A</p>
                  <p className="text mb-1">3102870102A1A</p>
                </Card.Body>
              </Card>
              <Card className="card-production-code">
                <Card.Header className="card-header-production-code">
                  CRAB MEAT - SPECIAL
                </Card.Header>
                <Card.Body className="card-body-production-code">
                  <p className="text mb-1">3102660218A1A</p>
                  <p className="text mb-1">3102660118A1A</p>
                  <p className="text mb-1">3102860102A1A</p>
                </Card.Body>
              </Card> */}
            </Card.Body>
          </Card>

          <div className="card-product-result" ref={resultsProduct}>
            {isLoading && <Skeleton />}

            {data !== null &&
              data !== 1 &&
              !isLoading &&
              data?.traceability_code && (
                <CardProductTraceability data={data} />
              )}

            {data !== null &&
              data !== 1 &&
              !isLoading &&
              data?.production_code && <CardProductProduction data={data} />}

            {data === 1 && !isLoading && (
              <Card
                style={{
                  width: "375px",
                  border: "none",
                  marginTop: "32px",
                  marginBottom: "80px",
                }}
              >
                <Card.Body>
                  <div className="d-flex justify-content-center align-items-center ">
                    <p>Data not found</p>
                  </div>
                </Card.Body>
              </Card>
            )}
          </div>
        </div>
      </div>
      {/* Section 1 End */}

      {/* Section 2 Start */}
      <div className="section-2">
        <div className="text-container">
          <p className="text">
            We offer complete transparency, allowing you to trace the journey of
            our product from bait to plate.
          </p>
        </div>

        <div className="image-container">
          <img src={PhoneFLI} className="image" alt="Phone FLI" />
        </div>
      </div>
      {/* Section 2 End */}

      {/* Section 3 Start */}
      <div className="section-3">
        <div className="top-side">
          <div className="text-container">
            <h3 className="header-text">Fresh. Sustainable. Traceable.</h3>
            <p className="text">
              It’s in our DNA to follow strict environmental and social
              standards to avoid overfishing and maintain the marine ecosystem.
              By choosing us, you are supporting a business that values ethics,
              quality, livelihood of our partner fishermen, and ensuring a
              healthy environment for generations to come.
            </p>
          </div>

          <div className="image-container">
            <img
              src={FishlogTraceWhite}
              className="image"
              alt="Fishlog Trace White"
            />
          </div>
        </div>

        <div className="verify-container">
          <div className="verify-item">
            <div className="img-background mb-3">
              <img src={DedicationToQuality} alt="Dedication To Quality" />
            </div>
            <h5 className="sub-text">DEDICATION TO</h5>
            <h3 className="text">QUALITY</h3>
          </div>

          <div className="verify-item">
            <div className="img-background mb-3">
              <img src={EthicalFishing} alt="Ethical Fishing" />
            </div>
            <h5 className="sub-text">ETHICAL</h5>
            <h3 className="text">FISHING</h3>
          </div>

          <div className="verify-item">
            <div className="img-background mb-3">
              <img src={VerifiedOrigins} alt="Verified Origins" />
            </div>
            <h5 className="sub-text">VERIFIED</h5>
            <h3 className="text">ORIGINS</h3>
          </div>
        </div>
      </div>
      {/* Section 3 End */}

      {/* Section 4 Start */}
      <div className="section-4">
        <div className="d-flex justify-content-start align-items-center">
          <img src={Phonetrace} className="image-phone" alt="Phonetrace" />
        </div>

        <div className="right-side">
          <div className="image-container">
            <img src={FishlogTraceBlue} alt="Fishlog Trace Blue" />
          </div>

          <div className="text-container">
            <h3 className="header-text">
              You have all your product’s important info displayed, included:
            </h3>

            <div className="item-list">
              <div className="text-item">
                <img src={ProductInfo} alt="Product Info" />
                <div className="ms-2">
                  <h5 className="subtitle">Product’s Information</h5>
                  <p className="text">
                    Raw materials, fishing origin, fishing method, processing
                    information, etc.
                  </p>
                </div>
              </div>

              <div className="text-item">
                <img
                  src={ProductJourney}
                  style={{ color: "#254e9c" }}
                  alt="Product Info"
                />
                <div className="ms-3">
                  <h5 className="subtitle">Product’s Journey</h5>
                  <p className="text">
                    Storage and manufacturer’s location, Port of departure
                  </p>
                </div>
              </div>

              <div className="text-item">
                <img src={Delivery} alt="Product Info" />
                <div className="ms-3">
                  <h5 className="subtitle">Delivery Information</h5>
                  <p className="text">
                    Port of origin and delivery destination
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section 4 End */}
    </>
  );
};

export default Scanner;
