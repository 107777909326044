import React from "react";
import { PolylineF } from "@react-google-maps/api";

const LineMap = (props) => {
  const { line } = props;

  const lineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 2,
    strokeColor: "#CB3A31",
  };

  const polygonProps = {
    options: {
      strokeOpacity: 0,
      icons: [
        {
          icon: lineSymbol,
          offset: "0",
          repeat: "10px",
        },
      ],
    },
    path: line,
  };

  return (
    <PolylineF visible={true} {...polygonProps}></PolylineF>
  );
};

export default LineMap;
