import { Col } from "react-bootstrap";

const CertificationData = ({ data }) => {
  return (
    <div className="image-certification">
      {data?.manufacturer_certification &&
        data?.manufacturer_certification.map((item, idx) => (
          <Col lg={3} md={3} sm={3} xs={3}>
            <div key={idx}>
              <img src={item.image} className="image" alt="certification logo" />
            </div>
          </Col>
        ))}
    </div>
  );
};

export default CertificationData;
