const removeLocalstorage = () => {
  localStorage.removeItem("token");
};

const monthName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const monthNameSimple = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Des",
];

const convertDateDash = (date) => {
  return date?.replaceAll("-", " ");
};

const calculateDateDifference = (date1, date2) => {
  let dateDifference = null;
  const date1Parts = date1?.split("-");
  const date2Parts = date2?.split("-");

  if (date1Parts?.length === 3 && date2Parts?.length === 3) {
    const year1 = parseInt(date1Parts[2], 10) + 2000;
    const month1 = new Date(
      Date.parse(`${date1Parts[1]} 1, ${year1}`)
    ).getMonth();
    const day1 = parseInt(date1Parts[0], 10);

    const year2 = parseInt(date2Parts[2], 10) + 2000;
    const month2 = new Date(
      Date.parse(`${date2Parts[1]} 1, ${year2}`)
    ).getMonth();
    const day2 = parseInt(date2Parts[0], 10);

    const date1Obj = new Date(year1, month1, day1);
    const date2Obj = new Date(year2, month2, day2);

    const timeDifference = Math.abs(date2Obj - date1Obj);
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    dateDifference = daysDifference;
  }

  return dateDifference ?? 0;
};

const convertTimeToDate = (item, isMonthLong = false) => {
  if (item == null || item === "") {
    return "";
  }
  const newDate = new Date(item * 1000);
  const date = newDate.getDate();
  let monthsx = newDate.getMonth();
  monthsx += 1;
  const year = newDate.getFullYear();
  let month = monthNameSimple[monthsx - 1];
  if (isMonthLong) {
    month = monthName[monthsx - 1];
  }
  let h = newDate.getHours().toString();
  let m = newDate.getMinutes().toString();
  let s = newDate.getSeconds().toString();
  if (h.length < 2) {
    h = `0${h}`;
  }
  if (m.length < 2) {
    m = `0${m}`;
  }
  if (s.length < 2) {
    s = `0${s}`;
  }
  const dTime = h + ":" + m;
  return {
    date,
    month,
    year,
    dTime,
    full: `${date} ${month} ${year}`,
    fullTime: `${date} ${month} ${year} ${dTime}`,
    ddmmyyyy: `${String(newDate.getDate()).padStart(2, "0")}-${String(
      monthsx
    ).padStart(2, "0")}-${year}`,
    yyyymmdd: `${year}-${String(monthsx).padStart(2, "0")}-${String(
      newDate.getDate()
    ).padStart(2, "0")}`,
    ddmmyyyyTimeSlash: `${String(newDate.getDate()).padStart(2, "0")}/${String(
      monthsx
    ).padStart(2, "0")}/${year} ${dTime}`
  };
};

export {
  removeLocalstorage,
  convertDateDash,
  calculateDateDifference,
  convertTimeToDate
};
