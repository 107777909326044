import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import ButtonNextPrev from "../../../components/Pagination/Button";
import useWindowSize from "../../../components/WinSize";

const SupplierTables = ({ data, dataTrace }) => {
  const [winWidth, _] = useWindowSize();
  const [pagin, setPagin] = useState({
    start: 0,
    end: 5,
  });

  const [paginMobile, setPaginMobile] = useState({
    start: 0,
    end: 1,
  });

  const length = dataTrace.suppliers.length;

  const [suppInfo, setSuppInfo] = useState();

  const paginate = () => {
    setSuppInfo(dataTrace.suppliers.slice(pagin.start, pagin.end));
  };

  const paginateMobile = () => {
    setSuppInfo(dataTrace.suppliers.slice(paginMobile.start, paginMobile.end));
  };

  useEffect(() => {
    if (winWidth <= 560) {
      paginateMobile();
    } else {
      paginate();
    }
  }, [paginMobile.start, paginMobile.end, pagin.start, pagin.end, winWidth]);

  return (
    <>
      {winWidth <= 560 ? (
        <>
          {suppInfo && (
            <Table
              className="table table-bordered mt-3 mb-3 fl_table"
              style={{ background: "yellow" }}
            >
              <thead></thead>
              <tbody>
                <tr>
                  <th>No.</th>
                  <td>{paginMobile.end}</td>
                </tr>
                <tr>
                  <th>Supplier Name</th>
                  <td>
                    {suppInfo[0]?.first_name} {suppInfo[0]?.last_name}
                  </td>
                </tr>
                <tr>
                  <th>Catching Method</th>
                  <td>{suppInfo[0]?.catching_method}</td>
                </tr>
                <tr>
                  <th>Catching Area</th>
                  <td>{suppInfo[0]?.catching_area}</td>
                </tr>
                <tr>
                  <th>Supply Point</th>
                  <td>{suppInfo[0]?.supply_point}</td>
                </tr>
              </tbody>
            </Table>
          )}
          <p>
            Showing {paginMobile.start + 1} of {data && length} entries
          </p>
          <ButtonNextPrev
            type="mobile"
            data={dataTrace.suppliers}
            pagin={paginMobile}
            paginStart={paginMobile.start}
            paginEnd={paginMobile.end}
            setPagin={setPaginMobile}
            setArr={setSuppInfo}
          />
        </>
      ) : (
        <>
          <Table className="table table-bordered mt-3 mb-3 fl_table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Supplier Name</th>
                <th>Catching Method</th>
                <th>Catching Area</th>
                <th>Supply Point</th>
              </tr>
            </thead>
            <tbody>
              {suppInfo &&
                suppInfo.map((item, idx) => {
                  return (
                    <tr key={item.id}>
                      <td>{pagin.start + idx + 1}</td>
                      <td>
                        {item.first_name} {item.last_name}
                      </td>
                      <td>{item.catching_method}</td>
                      <td>{item.catching_area}</td>
                      <td>{item.supply_point}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <div>
            <Row>
              <Col lg={6} md={6}>
                <p>
                  Showing {pagin.start + 1} to{" "}
                  {length < pagin.end + 1 ? length : pagin.end + 1} of{" "}
                  {data && length} entries
                </p>
              </Col>
              <Col
                lg={6}
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <ButtonNextPrev
                  type="website"
                  data={dataTrace.suppliers}
                  pagin={pagin}
                  paginStart={pagin.start}
                  paginEnd={pagin.end}
                  setPagin={setPagin}
                  setArr={setSuppInfo}
                />
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default SupplierTables;
