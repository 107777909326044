import React from "react";
import { Col, Row } from "react-bootstrap";

// Assets
import Storage from "../../../assets/images/icons/storage.svg";
import Factory from "../../../assets/images/icons/factory.svg";
import Anchor from "../../../assets/images/icons/anchor.svg";
import Destination from "../../../assets/images/icons/destination.svg";

// Utils
import { convertTimeToDate } from "../../../utils";

const SeafoodJourneyMobile = ({
  data,
  handleChangeActiveCard,
  dataCard,
  setDataCard,
  activeCard,
  latlngStorage,
  latlngDeparture,
  latlngManufacture,
  latlngArrival,
}) => {
  return (
    <>
      <div className="card-port-container d-flex">
        <Row>
          <Col>
            {/* Storage */}
            <div
              className={`mb-3 card-port-mobile ${
                activeCard === "storage" ? "active" : ""
              } `}
              onClick={() => (
                handleChangeActiveCard("storage", {
                  lat: Number(latlngStorage.lat),
                  lng: Number(latlngStorage.lng),
                }),
                setDataCard({
                  image: Storage,
                  title: "Storage",
                  subtitle: data.storage,
                  lat: latlngStorage.lat,
                  lng: latlngStorage.lng,
                  date: data.storage_date,
                })
              )}
            >
              <div className="align-items-center mb-2">
                <img src={Storage} alt="Storage" className="" />
                <p className="subtitle">Storage</p>
              </div>
            </div>
          </Col>

          {/* Manufacture */}
          <Col>
            <div
              className={`mb-3 card-port-mobile ${
                activeCard === "manufacture" ? "active" : ""
              } `}
              onClick={() => (
                handleChangeActiveCard("manufacture", {
                  lat: Number(latlngManufacture.lat),
                  lng: Number(latlngManufacture.lng),
                }),
                setDataCard({
                  image: Factory,
                  title: "Manufacture",
                  subtitle: data.manufacture,
                  lat: latlngManufacture.lat,
                  lng: latlngManufacture.lng,
                  date: data.manufacture_date,
                })
              )}
            >
              <div className="align-items-center mb-2">
                <img src={Factory} alt="Factory" className="" />
                <p className="subtitle">Manufacture</p>
              </div>
            </div>
          </Col>

          {/* Departure */}
          <Col>
            <div
              className={`mb-3 card-port-mobile ${
                activeCard === "port_of_departure" ? "active" : ""
              } `}
              onClick={() => (
                handleChangeActiveCard("port_of_departure", {
                  lat: Number(latlngDeparture.lat),
                  lng: Number(latlngDeparture.lng),
                }),
                setDataCard({
                  image: Anchor,
                  title: "Port of Departure",
                  subtitle: data?.port_of_departure,
                  lat: latlngDeparture.lat,
                  lng: latlngDeparture.lng,
                  date: data.actual_time_depature,
                })
              )}
            >
              <div className="align-items-center mb-2">
                <img src={Anchor} alt="Anchor" className="" />
                <p className="subtitle">Port of Departure</p>
              </div>
            </div>
          </Col>

          {/* Destination */}
          <Col>
            <div
              className={`mb-3 card-port-mobile ${
                activeCard === "destination" ? "active" : ""
              } `}
              onClick={() => (
                handleChangeActiveCard("destination", {
                  lat: Number(latlngArrival.lat),
                  lng: Number(latlngArrival.lng),
                }),
                setDataCard({
                  image: Destination,
                  title: "Destination",
                  subtitle: data?.destination
                    ? data.destination
                    : data.port_of_destination_name,
                  lat: latlngArrival.lat,
                  lng: latlngArrival.lng,
                  date: data.estimate_time_arrival,
                })
              )}
            >
              <div className="align-items-center mb-2">
                <img src={Destination} alt="Anchor" className="" />
                <p className="subtitle">Destination</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      {activeCard && (
        <div
          className="active-card"
          style={{
            width: "100%",
            border: "1px solid #254e9c",
            backgroundColor: "#f0f5ff",
            padding: "10px",
            borderRadius: "8px",
          }}
        >
          <div>
            <p className="title mb-1">{dataCard.title}</p>
            <div className="d-flex align-items-center mb-2 center" style={{}}>
              <img src={dataCard.image} alt="Anchor" className="me-2" />
              <p className="subtitle">{dataCard?.subtitle}</p>
            </div>
            <p className="subtitle">
              {dataCard?.lat ? dataCard?.lat : "-"},{" "}
              {dataCard?.lng ? dataCard?.lng : "-"}
            </p>
            <p className="subtitle">{convertTimeToDate(dataCard.date).full}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default SeafoodJourneyMobile;
