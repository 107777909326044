import service from "./service";
import endpoint from "./constantURL";

export const getDataTraceabilityByTraceabilityCode = (production_code) => {
  return service.get(
    `${endpoint.TRACEABILITY_URL}/api/traceability/traceability-code/${production_code}`
  );
};

export const getDataTraceabilityByProductionCode = (production_code) => {
  return service.get(
    `${endpoint.TRACEABILITY_URL}/api/traceable/trace/${production_code}`
  );
};
