import { Row } from "react-bootstrap";

const QcReportData = ({ dataTrace }) => {
  return (
    <Row className="list-text">
      {dataTrace.qc_report &&
        dataTrace.qc_report.map((item, idx) => (
          <div key={idx} style={{ marginBottom: "5px" }}>
            <a href={item} target="_blank" rel="noopener noreferrer">
              {idx + 1}. Link Report <i class="bx bx-link-external"></i>
            </a>
          </div>
        ))}
    </Row>
  );
};

export default QcReportData;
